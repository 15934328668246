// Font size variables
$font-size-xxs: 0.8vw;
$font-size-xs: 1.1vw;
$font-size-sm: 1.4vw;
$font-size-md: 1.6vw;
$font-size-lg: 1.9vw;

@media (max-width: 550px) {
  $font-size-xxs: 2.5vw;
  $font-size-xs: 3vw;
  $font-size-sm: 3.5vw;
  $font-size-md: 4vw;
  $font-size-lg: 4.5vw;
}

.table {
  font-family: Arial, sans-serif;
  max-width: 90vw;
  margin: 0 auto;
  // font-size: $font-size-xs;
width: inherit;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;

    .emoji {
      font-size: $font-size-md;
      margin-bottom: 2em;
    }

    h2 {
      margin: 0;
      font-weight: bold;
      font-size: $font-size-sm;
      @media (max-width: 550px) {
        font-size: 4vw !important;
      }
    }
  }

  h3 {
    color: #000;
    font-size: $font-size-xs;
    @media (max-width: 550px) {
      font-size: $font-size-md;
    }
    // margin-bottom: 2em;
    font-weight: 400;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: inline-block;
    width: 80%;
    margin-top: 0;
  }

  .word-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;

    .emoji {
      font-size: $font-size-xs;
      width: 6em;
      margin-right: 2em;
    }

    .word {
      font-weight: bold;
      color: #4a7c59;
      width: 15em;
      font-size: $font-size-xs;
    }

    .reasoning {
      flex-grow: 1;
      font-size: $font-size-xxs;
      color: #555;
      margin-right: 2em;
    }

    .score {
      font-weight: bold;
      color: #4a7c59;
      font-size: $font-size-xs;
    }
  }
  .scored h3 {
    color: #4a7c59;
  }

  .missed h3 {
    color: #e15b64;
  }

  .missed .word-row {
    .word, .score {
      color: #e15b64;
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-top: 4em;
    padding-top: 2em;
    border-top: 0.2em solid #ccc;
    font-size: $font-size-xs;
  }

  .percentage {
    text-align: right;
    font-size: $font-size-sm;
    font-weight: bold;
    color: #4a7c59;
    margin-top: 1em;
  }

  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .modal-content {
    background-color: #fff;
    border-radius: 1em;
    overflow-y: auto;
    width: 90%;
  }

  .overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -999;
  }
  .roundsLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #EE5A5A;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2vw;
    font-size: min(2vw, 24px);
    @media (max-width: 550px) {
      font-size: 4vw;
      top: 5px;
      right: 5px;
    }
  }
}