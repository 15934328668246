.droppable {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: fit-content;
  border-radius: 0.5vh;
  background: #747474; // Change to your desired color

}

.droppable__outline {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: .6vh solid transparent; // Start with a transparent border
  border-radius: 0.5vh;
  pointer-events: none;
  z-index: 1;
  
//   transition: border-color 0.3s ease;

}

.droppable--is-over .droppable__outline {
  background: #000000; // Change to your desired color
  border-radius: 0.5vh;
  opacity: 0.5;

}