// Variables
$primary-color: #628659;
$secondary-color: #fff;
$danger-color: #EE5A5A;
$text-color: #000;
$border-radius: min(0.25vh, 0.25vw);
$button-height: 4vh;
$button-width: auto;
$gap: min(1vw, 1vh);
$font-size-base: 2vw;
$font-size-heart: 3vw;
$font-size-small: 1.5vw;
$font-size-large: 2.5vw;
$font-size-xlarge: 3vw;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin button-base {
  border: none;
  border-radius: $border-radius;
  height: $button-height;
  cursor: pointer;
  @include flex-center;
  font-size: $font-size-base;
}

// Main game screen
#main-game-screen {
  // width: 40%;
  // max-width: calc(500px + 2.5vw);
  
  height: 100%;
  
  // max-height: 95vw;
  @media (max-width: 550px) {
    // top: 2.55vh;
    // max-width: 89vw;
    // width: 95vw;
    justify-content: start;
    display: flex;
    flex-direction: column;
    justify-content: top;
    min-height: 100vh;

    position: absolute;
    margin-top: 3vh;
    // top: 0;
  } 
  @include absolute-center;
  transform: translate(-50%, -50%);
  @include flex-center;
  flex-direction: column;
  font-size: $font-size-base;

  // Game state
  .game-state {
    color: $secondary-color;
    position: relative;
  }

  // Flip button
  .flip {
    @include button-base;
    background-color: $primary-color;
    color: $text-color;
    height: min(4vh, 4vw);
    width: $button-width;
    margin: min(1vh, 1vw);
    user-select: none;
    position: relative;
  }

  // Extra game controls
  .extra-game-controls {
    display: flex;
    grid-template-columns: 1fr 1fr;
    gap: $gap;
    position: relative;
    width: 100%;
    max-width: calc(750px + 2vw);

    /* @media (max-width: 550px) {
      width: 100%;
    } */
    height: $button-height;
    margin-bottom: min(1vh, 1vw);

    .go,
    .lock {
      @include button-base;
      background-color: $primary-color;
      color: $secondary-color;
      width: 100%;
      font-size: large;
      .guessText{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        text-align: center;
      }
      .roundNum {
        font-size: $font-size-large;
        background-color: $secondary-color;
        color: $primary-color;
        border-radius: 2vh;
        width: $button-height;
        height: calc(0.7 * $button-height);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-size: large;

        margin-right: 0.5em;
      }
      .roundsLeft{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        font-size: large;
        @media (max-width: 550px) {
          font-size: $font-size-heart ;
        }
        padding: 0.1em;
        margin-right: 0.1em;
        .heart {
          margin-right: 0.1em; // Adjust this value to increase or decrease spacing
          &:last-child {
            margin-right: 0; // Remove margin from the last heart
          }
        }
      }
    }
    .restart {
      border: none;
      background-color: $danger-color;
      color: $secondary-color;
      font-size: large;
      border-radius: $border-radius;
      width: 2*$button-height;
    }
    .clear{
      border: none;
      font-size: large;
      // padding: min(2vh, 2vw);
      border-radius: $border-radius;
      width: 2*$button-height;
    }
  }

  // Main game controls
  .game-controls {
    position: relative;
    display: flex;
    gap: $gap;
    width: 100%;
    user-select: none;
    margin-bottom: min(1vh, 1vw);

    .restart,
    .go,
    .lock,
    .reroll,
    .clear {
      @include button-base;
      width: 100%;
      border: none;
    }
    .clear{
      border: none;
    }
    .restart {
      background-color: $danger-color;
      color: $text-color;
    }

    .go,
    .lock {
      background-color: $primary-color;
      color: $secondary-color;
    }

    .reroll {
      background-color: $secondary-color;
    }
  }

  // Die containers
  .player-die-container {
    grid-template-columns: repeat(5, 0fr);
    position: relative;
    display: grid;
    justify-content: center;
    gap: min(1vw, 1vh);
    width: 100%;
    margin-bottom: min(1vh, 1vw);
    border-radius: $border-radius;

    /* @media (max-width: 550px) {
      display: grid;
      grid-template-columns: repeat(5, 0fr);
      max-width: 100%;
      overflow-y: auto;
      // max-height: 60vh;
      height: auto;
      // min-height: 100%;
      // // min-height: min-content;
      width: 100%;

      justify-content: center;
      align-items: start;
      gap: min(1vw, 1vh);
      z-index: 10;
      // padding: min(1vw, 1vh);

      // &::before {
      //   content: '';
      //   display: block;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   right: 0;
      //   height: 100%;
      //   max-height: min(60vh, 60vw);
      //   pointer-events: none;
      // }
    } */
  }

  .empty-die-container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 0fr);
    gap: min(1vw, 1vh);
    width: 100%;
    margin-bottom: min(1.5vh, 1.5vw);
    justify-content: center;
    border-radius: $border-radius;
    z-index: -1;
  }

  .enemy-die-container {
    position: relative;
    display: flex;
    gap: $gap;
    width: 100%;
    margin-bottom: min(1vh, 1vw);
  }

  // Combined attack displays
  .combined-attack-player,
  .combined-attack-enemy {
    position: relative;
    background-color: $secondary-color;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: min(2vh, 2vw);
    box-sizing: border-box;
    width: 100%;
    // max-width: calc(750px + 2vw);
    margin-bottom: min(1vh, 1vw);
    z-index: 10;
    // height: 12vh;
    padding: 0;
    padding-top: min(1vh, 1vw);
    padding-bottom: min(1vh, 1vw);


    .emoji {
      font-size: clamp(24px, min(5vh, 5vw), 48px);
      margin: 0;
      /* @media (max-width: 550px) {
        font-size: clamp(36px, min(7vh, 7vw), 72px);
      } */
    }

    h2 {
      font-weight: bold;
      margin: 0;
      font-size: $font-size-small;
      /* @media (max-width: 550px) {
        font-size: $font-size-xlarge;
      } */
    }

    .body {
      font-weight: normal;
      margin: 0;
      font-size: $font-size-base;
      /* @media (max-width: 550px) {
        font-size: $font-size-large;
      } */
    }
  }

  // .diceHover {
  //   top: auto !important;
  //   left: auto !important;
  //   // Size for smaller screens
  //   /* @media (max-width: 550px) {
  //     width: 17vw;
  //     height: 17vw;
  //   } */
  // }
}

:root {
  --dice-width: 10vw;
}