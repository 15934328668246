.dice {
  width: 10vh;
  height: 10vh;
  max-width: 100px;
  max-height: 100px;
  $font-size-small: max(.8vw, 8px);
  $font-size-large: max(2vh, 20px);
  $font-size-guess: max(.8vw, 8px);
  $font-size-small-mobile: max(1.5vw, 10px);
  $font-size-large-mobile: max(6vw, 24px);
  $font-size-guess-mobile: max(3vw, 24px);
  color: #000000;

  .player-die-container & {
    @media (max-width: 550px) {
      width: 16vw;
      height: 16vw;
    }
  }
  .empty-die-container & {
    @media (max-width: 550px) {
      width: 16vw;
      height: 16vw;
    }
  } 
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-small;
  border-radius: .5vh;
  font-weight: 600;
  user-select: none;
  box-sizing: border-box;

  &.blue, &.green {
    background: rgb(49, 103, 212);
    color: #fff !important;
  }

  &.guess.unlocked {
    // opacity: 0.25;
    background: #747474;
  }
  &.missing{
    background: #EE5A5A !important;
    color: #fff !important;
  }
  &.wrong {
    opacity: 0.5;
    background-color: #3f3f3f;
    color: #fff !important;
  }

  &.correct {
    background: #628659;
    color: #fff !important;
  }

  &.off {
    background: #FF9800;
    color: #fff !important;
  }

  .die-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .value, .emoji, .word {
    margin: 0;
    padding: 0;
  }

  .value {
    font-size: $font-size-small;
    @media (max-width: 550px) {
      font-size: $font-size-small-mobile;
    }
  }

  .emoji {
    font-size: $font-size-large;
    @media (max-width: 550px) {
      font-size: $font-size-large-mobile;
    }
  }

  .word {
    font-size: $font-size-small;
    @media (max-width: 550px) {
      font-size: $font-size-small-mobile;
    }
  }

  .empty-die-container & .word {
    font-size: $font-size-small;
    @media (max-width: 550px) {
      font-size: $font-size-small-mobile !important;
    }
  }
}